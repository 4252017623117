const initState = {
	user : localStorage.getItem('user'),

}

const user = (state = initState, action) => {
	switch (action.type) {
		case 'LOGINUSER': 
			return {
				...state,
				user: action.user
			}
		default:
			return state;
	}
}

export default user