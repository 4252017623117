import React, { useEffect, useState } from "react";

const PermisssionGate = ({
  role,
  children,
}) => {
  const [permissions, setPermissions] = useState([]);

  const permission = localStorage.getItem('permission')

  useEffect(() => {

    const permission_replace = permission.replace(/[{}]/g, "")
    const permission_split = permission_replace.split(",")

    async function loadRoles() {

      const findRole = permission_split.some((r) =>
        role?.split(",").includes(r)
      );

      setPermissions(findRole);
    }

    loadRoles();
  }, []);

  return <>{permissions && children}</>;
};

export default PermisssionGate;