import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import PrivateRoutes from "Permission/PrivateRoutes";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/admin`} component={lazy(() => import(`./admin`))} />
        <Route path={`${APP_PREFIX_PATH}/admin-regional`} component={lazy(() => import(`./admin-regional`))} />
        <Route path={`${APP_PREFIX_PATH}/admin-country`} component={lazy(() => import(`./admin-country`))} />
        <Route path={`${APP_PREFIX_PATH}/doctors`} component={lazy(() => import(`./doctors`))} />
        <Route path={`${APP_PREFIX_PATH}/seller`} component={lazy(() => import(`./sellers`))} />
        <Route path={`${APP_PREFIX_PATH}/patients`} component={lazy(() => import(`./patients`))} />
        <Route path={`${APP_PREFIX_PATH}/companys`} component={lazy(() => import(`./companys`))} />
        <Route path={`${APP_PREFIX_PATH}/products`} component={lazy(() => import(`./products`))} />
        <Route path={`${APP_PREFIX_PATH}/combos`} component={lazy(() => import(`./combos`))} />
        <Route path={`${APP_PREFIX_PATH}/coupons`} component={lazy(() => import(`./coupons`))} />
        <Route path={`${APP_PREFIX_PATH}/order`} component={lazy(() => import(`./order`))} />
        <Route path={`${APP_PREFIX_PATH}/tags`} component={lazy(() => import(`./tags`))} />
        <Route path={`${APP_PREFIX_PATH}/tracking`} component={lazy(() => import(`./tracking`))} />
        <Route path={`${APP_PREFIX_PATH}/clinic`} component={lazy(() => import(`./clinic`))} />
        <Route path={`${APP_PREFIX_PATH}/channel`} component={lazy(() => import(`./channel`))} />
        <Route path={`${APP_PREFIX_PATH}/laboratories`} component={lazy(() => import(`./laboratories`))} />
        <Route path={`${APP_PREFIX_PATH}/payments-new`} component={lazy(() => import(`./payments`))} />
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} /> 
        <Route path={`${APP_PREFIX_PATH}/statistics`} component={lazy(() => import(`./statistics`))} /> 
        <Route path={`${APP_PREFIX_PATH}/results`} component={lazy(() => import(`./results`))} /> 
        <Route path={`${APP_PREFIX_PATH}/comission`} component={lazy(() => import(`./comissions`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
