import React from 'react'
import { connect } from 'react-redux';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import utils from 'utils'
import MenuPerson from './MenuPerson'

export const TopPerson = ({topNavColor, localization = true}) => {
	const props = { topNavColor, localization }
	return (
		<div className={`top-nav top-menu-doctor mt-2 ${utils.getColorContrast('#FBFBFC')}`} style={{backgroundColor: '#FBFBFC'}}>
			<div className="top-nav-wrapper">
				<MenuPerson 
					type={NAV_TYPE_TOP} 
					{...props}
				/>
			</div>
		</div>
	)
}

const mapStateToProps = ({ theme }) => {
  const { topNavColor } =  theme;
  return { topNavColor }
};

export default connect(mapStateToProps)(TopPerson);
