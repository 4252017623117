import antdEnBR from 'antd/es/locale/pt_BR';
import enMsg from "../locales/pt_BR.json";

const EnLang = {
  antd: antdEnBR,
  locale: 'pt-BR',
  messages: {
    ...enMsg
  },
};
export default EnLang;
