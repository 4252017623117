import {
  DashboardOutlined,
  IdcardOutlined,
  MedicineBoxOutlined,
  HomeOutlined,
  ShoppingOutlined,
  RiseOutlined,
  ReconciliationOutlined,
  DotChartOutlined,
  TagOutlined,
  SolutionOutlined,
  TagsOutlined,
  CreditCardOutlined,
  EnvironmentOutlined,
  BarChartOutlined,
  QuestionCircleOutlined

} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'sidenav.home',
  icon: '',
  breadcrumb: true,
  submenu: [
    {
      key: 'home-title',
      role: 'ba3988db0a3167093b1f74e8ae4a8e83',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'sidenav.home',
      icon: HomeOutlined,
      breadcrumb: true,
      submenu: []
    },
    {
      key: 'admin-sub',
      role: 'e940f7d05beef544f58c9739dd19c79a',
      path: `${APP_PREFIX_PATH}`,
      title: 'sidenav.register.user',
      icon: SolutionOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-new',
          role: '8941a3112f8d9f7ba10b995d9243a17a',
          path: `${APP_PREFIX_PATH}/admin/new`,
          title: 'sidenav.admin.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'admin-regional-new',
          role: '351c79cc3ecad5295b8e1afc5750ecc1',
          path: `${APP_PREFIX_PATH}/admin-regional/new`,
          title: 'sidenav.admin.regional.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'admin-country-new',
          role: '5e39c9a48301e6044788caeb30c16773',
          path: `${APP_PREFIX_PATH}/admin-country/new`,
          title: 'sidenav.admin.country.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'seller-new',
          role: '148b768454407db76ed1a4f8470eb974',
          path: `${APP_PREFIX_PATH}/seller/new`,
          title: 'sidenav.seller.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'company-new',
          role: '2fa912ffc44a3e7ab7adf0038d798f4e',
          path: `${APP_PREFIX_PATH}/companys/new`,
          title: 'sidenav.company.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'clinic-new',
          path: `${APP_PREFIX_PATH}/clinic/new`,
          role: '14333b7836aefdf87268a0eeef3dc6ee',
          title: 'sidenav.clinic.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'channel-new',
          path: `${APP_PREFIX_PATH}/channel/new`,
          role: '6da24c9371367f45b209930524dd1292',
          title: 'sidenav.channel.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'doctor-down-new',
          role: 'a25d0a46b4a3fc6bcc60d8a1251d55a4',
          path: `${APP_PREFIX_PATH}/doctors/new`,
          title: 'sidenav.doctor.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'patient-new',
          role: '76ea0bebb3c22822b4f0dd9c9fd021c5',
          path: `${APP_PREFIX_PATH}/patients/new`,
          title: 'sidenav.patient.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'product-new',
          role: 'bb594b10b4e7f862cc781c5fe87a8322',
          path: `${APP_PREFIX_PATH}/products/direction`,
          title: 'sidenav.product.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
       /* {
          key: 'coupons-new',
          role: '67cf22074a529ce9b62ef9cfd3dac5d8',
          path: `${APP_PREFIX_PATH}/coupons/new`,
          title: 'sidenav.coupons.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        }, */
        {
          key: 'laboratories-new',
          path: `${APP_PREFIX_PATH}/laboratories/new`,
          role: 'ce487c97fde52fee8a8b287a2adfdf14',
          title: 'sidenav.laboratories.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },

      ]
    },
    {
      key: 'consult-sub',
      role: '5e73b42456347af1be4be2d0c8eda64a',
      path: `${APP_PREFIX_PATH}/home`,
      title: 'sidenav.consult',
      icon: IdcardOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'admin-list',
          role: 'e0068fc79ad227c7549334db69c372ac',
          path: `${APP_PREFIX_PATH}/admin/list`,
          title: 'sidenav.admin.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'admin-regional-list',
          role: 'bf566c8e5263b7689dddd444e699d89f',
          path: `${APP_PREFIX_PATH}/admin-regional/list`,
          title: 'sidenav.admin.regional.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'admin-country-list',
          role: '05b4a795ae407ff178e28fc8f98a20e6',
          path: `${APP_PREFIX_PATH}/admin-country/list`,
          title: 'sidenav.admin.country.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'seller-list',
          role: 'eda01d40a2858fa244c96b04b22fef10',
          path: `${APP_PREFIX_PATH}/seller/list`,
          title: 'sidenav.seller.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'company-list',
          role: '32e0888cb603523494c4849e72f6226f',
          path: `${APP_PREFIX_PATH}/companys/list`,
          title: 'sidenav.company.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'clinic-list',
          path: `${APP_PREFIX_PATH}/clinic/list`,
          role: '47d17df725bcd6ae26ab093d70a63050',
          title: 'sidenav.clinic.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'channel-list',
          path: `${APP_PREFIX_PATH}/channel/list`,
          role: '41c1416f646e385eebdda4bf1c271cc7',
          title: 'sidenav.channel.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'doctor-down-list',
          role: 'bb1456a09b5e30cffe8168223e338530',
          path: `${APP_PREFIX_PATH}/doctors/list`,
          title: 'sidenav.doctor.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'patient-list',
          role: 'de78bbff8bf6876a1834b38339a62724',
          path: `${APP_PREFIX_PATH}/patients/list`,
          title: 'sidenav.patient.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'laboratories-list',
          path: `${APP_PREFIX_PATH}/laboratories/list`,
          role: '79412b61d9806c5a73f18beb6bbfa06f',
          title: 'sidenav.laboratories.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
       /* {
          key: 'coupons-list',
          role: '2abdfa9834957086d53423b1659bf905',
          path: `${APP_PREFIX_PATH}/coupons/list`,
          title: 'sidenav.coupons.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        }, */
      ]
    },
    {
      key: 'tag-sub',
      role: 'c592e5ae228fdc96a514a6fb341ca335,5cde7c39ad8488f541502a6e9395ef29',
      path: `${APP_PREFIX_PATH}/tags`,
      title: 'sidenav.tag',
      icon: TagsOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'tag-new',
          role: 'c592e5ae228fdc96a514a6fb341ca335',
          path: `${APP_PREFIX_PATH}/tags/new`,
          title: 'sidenav.tag.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'tag-list',
          role: '5cde7c39ad8488f541502a6e9395ef29',
          path: `${APP_PREFIX_PATH}/tags/list`,
          title: 'sidenav.tag.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'order-sub',
      role: '75cca03f77b0f13ea89ebc6c51a28996,23766c4cc5b6cd5f155fff4aac3ff94e',
      path: `${APP_PREFIX_PATH}/order`,
      title: 'sidenav.pedidos',
      icon: ReconciliationOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'order-new',
          role: '23766c4cc5b6cd5f155fff4aac3ff94e',
          path: `${APP_PREFIX_PATH}/order/new`,
          title: 'sidenav.pedidos.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'order-new-add',
          role: '23766c4cc5b6cd5f155fff4aac3ff94e',
          path: `${APP_PREFIX_PATH}/order/new-add`,
          title: 'sidenav.pedidos.new-add',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'order-list',
          role: '75cca03f77b0f13ea89ebc6c51a28996',
          path: `${APP_PREFIX_PATH}/order/list`,
          title: 'sidenav.pedidos.list',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'tracking-sub',
      role: '616e96a5090dfe2b18def7e9b9dcd915,3e63b3cc6215715f38573f548ad9005b',
      path: `${APP_PREFIX_PATH}/tracking`,
      title: 'sidenav.tracking',
      icon: EnvironmentOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'tracking-new',
          path: `${APP_PREFIX_PATH}/tracking/consult`,
          role: '616e96a5090dfe2b18def7e9b9dcd915',
          title: 'sidenav.tracking.new',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
      ]
    },
    {
      key: 'result-sub',
      role: '262da59ef57e842c22990ea64d71fc1e',
      path: `${APP_PREFIX_PATH}/results`,
      title: 'sidenav.results',
      icon: DotChartOutlined ,
      breadcrumb: true,
      submenu: [
        {
          key: 'result-list',
          role: '262da59ef57e842c22990ea64d71fc1e',
          path: `${APP_PREFIX_PATH}/results/list`,
          title: 'sidenav.results.list',
          icon: '',
          breadcrumb: false,
          submenu: []
        }
      ]
    },
    {
      key: 'statistics-sub',
      role: '6f6c767773c530fdc07bcffb615be4f8',
      path: `${APP_PREFIX_PATH}/statistics`,
      title: 'sidenav.statistics',
      icon: BarChartOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'statistics-country',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-country`,
          title: 'sidenav.statistics.sales_country',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-manager',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-manager`,
          title: 'sidenav.statistics.sales_manager',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-corporate',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-corporate`,
          title: 'sidenav.statistics.sales_corporate',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-clinic',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-clinic`,
          title: 'sidenav.statistics.sales_clinics',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-month',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-month`,
          title: 'sidenav.statistics.sales_month',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-year',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-year`,
          title: 'sidenav.statistics.sales_year',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-prescriber',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/sales-prescriber`,
          title: 'sidenav.statistics.sales_prescriber',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        {
          key: 'statistics-product-sold',
          role: '6f6c767773c530fdc07bcffb615be4f8',
          path: `${APP_PREFIX_PATH}/statistics/product-sold`,
          title: 'sidenav.statistics.product_sold',
          icon: '',
          breadcrumb: true,
          submenu: []
        },
        
      ]
    },
    {
      key: 'comission-sub',
      role: '0fe3acda3c4e75f66f7791ef5b75d1cc',
      path: `${APP_PREFIX_PATH}/comission`,
      title: 'sidenav.comission',
      icon: RiseOutlined,
      breadcrumb: true,
      submenu: [
        {
          key: 'comission-list',
          role: '0fe3acda3c4e75f66f7791ef5b75d1cc',
          path: `${APP_PREFIX_PATH}/comission/list`,
          title: 'sidenav.comission.list',
          icon: '',
          breadcrumb: false,
          submenu: []
        }
      ]
    },
  ]
}]


const navigationConfig = [
  ...dashBoardNavTree,
]

export default navigationConfig;
