const FirebaseConfig = {
  apiKey: "AIzaSyBfyKLBA4YkCd2DkY1L74Y_gcqXHCnByXU",
  authDomain: "fulldna-fac5e.firebaseapp.com",
  databaseURL: "https://fulldna-fac5e-default-rtdb.firebaseio.com",
  projectId: "fulldna-fac5e",
  storageBucket: "fulldna-fac5e.appspot.com",
  messagingSenderId: "156953694520",
  appId: "1:156953694520:web:1cf872c64d9e71425071cd"
};

export default FirebaseConfig

